:root {
  --primary-color: #1798ab;
  --pressed-color: #189eb3e7;
  --primary-color-opaque: #1797ab3b;
  --green-button-color: rgba(90, 171, 23, 0.5);
  --blue-button-color: rgba(23, 161, 171, 0.5);
  --navbar-height: 44px;
  --menu-height: 133px;
  --noto: "Noto Sans", sans-serif;
  --urban: "Urbanist", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
